<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">黑名单管理</div>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-form-item label="记录值:">
            <el-input v-model="p.config" style="width: 180px;" clearable></el-input>
          </el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="
            p.page = 1;
          f5();
          ">查询</el-button>
          <el-button type="primary" icon="el-icon-plus" v-if="this.sa_admin.role != 1" size="mini" @click="add()">添加黑名单</el-button>
        </el-form-item>
      </el-form>
      <el-table :row-class-name="tableRowClassName" :data="dataList" :key="conditionsKey" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }" size="mini">
        <el-table-column label="id" prop="id" width="50px"> </el-table-column>
        <el-table-column label="黑名单类型" prop="type">
          <template slot-scope="s">
            <el-tag type="danger" size="mini" v-if="s.row.type == 1">IP黑名单</el-tag>
            <el-tag type="danger" size="mini" v-if="s.row.type == 2">支付宝UID黑名单</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="记录值" prop="config" width="130px">
          <template slot-scope="s">
            <el-tag type="info" size="mini">{{  s.row.config }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createdTime" min-width="135px"></el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="s">
            <el-button class="c-btn" type="danger" icon="el-icon-delete" @click="del(s.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <BlockAdd ref="block-add"></BlockAdd>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes, jumper" :current-page.sync="p.page" :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import BlockAdd from "./block-add.vue";

export default {
  components: { BlockAdd },
  data() {
    return {
      p: {
        config: '',
        page: 1,
        limit: 10,
      },
      title: "添加黑名单信息",
      userDialogVisible: false,
      dataCount: 0,
      conditionsKey: 0,
      selectList: [],
      dataList: [],
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        type: "get",
      };
      this.sa.ajax(
        "/v1/block/list",
        this.p,
        function (res) {
          this.dataList = res.data.records;
          this.dataCount = res.data.total;
        }.bind(this),
        defaultCfg
      );
    },
    // 删除
    del: function (data) {
      var defaultCfg = {
        type: 'delete', //是否是请求体请求
      };
      this.sa.ajax(
        "/v1/block/delete/" + data.id,
        function () {
          this.f5();
        }.bind(this), defaultCfg
      );
    },
    add: function () {
      this.$refs["block-add"].open(0, 2);
    },
    update: function (data) {
      this.$refs["block-add"].open(data, 2);
    },
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引放进row
      row.index = rowIndex;
    },
  },
  created: function () {
    this.f5();
  },
};
</script>

<style>
</style>
